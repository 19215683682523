<template>
  <div class="codeUsed">
    <img class="bg" src="@/assets/codeUsed/buy-again.png" alt="" />
    <div class="content">
      <div class="tip">
        <p class="p1">二维码已使用</p>
        <p class="p2">已于{{ participateTime }}参与活动</p>
      </div>
      <div class="bottom">
        <moreGift></moreGift>
      </div>
    </div>
  </div>
</template>

<script>
import { queryQrcodeScanFirstTime } from '@/api/index.js'
import moreGift from '@/components/moreGift/index.vue'
import preventBack from '@/mixins/prevent-browser-back'

export default {
  name: 'codeUsed',
  mixins: [preventBack],
  components: {
    moreGift
  },
  data() {
    return {
      participateTime: '' //参与活动时间 yyyy-MM-dd HH:mm:ss
    }
  },
  methods: {
    getQrcodeScanFirstTime() {
      queryQrcodeScanFirstTime().then(({ data }) => {
        sessionStorage.setItem('participateTime', data.participateTime)
        this.participateTime = data.participateTime
      })
    }
  },
  created() {
    this.getQrcodeScanFirstTime()
  }
}
</script>

<style scoped lang="less">
.codeUsed {
  width: 100%;
  height: 100%;
  // background: #abb776;
  background: #fff;
  position: relative;
  .bg {
    width: 100%;
  }
  .content {
    position: absolute;
    top: 45px;
    width: 100%;

    .tip {
      width: 604px;
      height: 100px;
      background: rgba(75, 96, 14, 0.3);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;


      .p1 {
        font-size: 35px;
        color: #fcee98;
        margin-top:8px;
        margin-bottom:2px;
      }
      .p2 {
        font-size: 24px;
        color: #fcee98;
      }
    }
    .bottom {
      margin-top: 910px;
    }
  }
}
</style>
